//路由守卫
export default function(router){
    router.beforeEach((to,from,next)=>{
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title
    }

    // if (to.meta.title) {
    //   document.title = '数据作战室'
    // }


        next()
    })
    return router
}