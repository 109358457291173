
// const LOCALURL = '/api';//代理
// const LOCALURL = 'http://8.142.24.101:10031'

const LOCALURL = 'https://babb.boaiyiyao.com/api' //线上

// const LOCALURL = 'https://33a115e106.zicp.fun'//本地

const API = {//接口url
    register_api : LOCALURL + '/renren-fast/app/register',//注册 Post 
    login_api : LOCALURL + '/renren-fast/app/login',//登录 Post
    menu_api : LOCALURL + '/renren-fast/app/info/menu',//获取当前用户菜单接口 Post
    data_api : LOCALURL + '/renren-fast/info/data',//获取当前菜单数据 Post
    user_api : LOCALURL + '/renren-fast/app/info/user',//获取当前用户 Post
    view1_api : LOCALURL + '/renren-fast/app/info/view',//获取看版数据 Post
    viewmenu_api : LOCALURL + '/renren-fast/app/info/viewmenu',//获取看版显示名 Post
    curve_api : LOCALURL + '/renren-fast/app/info/curve',//获取曲线图及销售排名数据 Post
    declare_api : LOCALURL + '/renren-fast/info/declare',//博爱供应商应付报表
    amountData_api :LOCALURL  + '/renren-fast/info/amountData',//以销定结供应商应付报表
    update_api : LOCALURL +  '/renren-fast/app/update',//修改密码
    curve2_api : LOCALURL + '/renren-fast/app/info/curve2',//电商销售排行
    filter_api : LOCALURL + '/renren-fast/info/menu/filter',//获取过滤项信息
    filter3_api : LOCALURL + '/renren-fast/info/menu/filter/3',//获取下拉框内容
    getShopByArea : LOCALURL + '/renren-fast/user/getShopByArea',//获取区域详情
    functionList:LOCALURL + '/renren-fast/app/v1/function/list',//查询功能列表
    functionInfo:LOCALURL + '/renren-fast/app/v1/function/info/',//查询功能菜单详情 
    //盘点
    inventoryList:LOCALURL + '/renren-fast/app/v1/inventory/inventoryList',//盘点List
    // detailList:LOCALURL + '/renren-fast/app/v1/inventory/inventoryInfo/',//详情List
    goodsList:LOCALURL + '/renren-fast/app/v1/inventory/queryGoodsInfo',//商品List
    syncRealityQuantityList:LOCALURL + '/renren-fast/app/v1/inventory/syncRealityQuantity',//批号盘点
    differentList:LOCALURL + '/renren-fast/app/v1/inventory/different',//查看差异
    unCalContList:LOCALURL + '/renren-fast/app/v1/inventory/unCalContList',//未盘点数量
    checkAvailable:LOCALURL + '/renren-fast/app/v1/inventory/checkAvailable',//查看差异
    options666:LOCALURL + '/renren-fast/app/v1/inventory/options666',//输入货号返回批号List
    goodsStatisticsList:LOCALURL + '/renren-fast/app/v1/inventory/searchGoods',//输入商品返回商品信息
    goodsDetailList:LOCALURL + '/renren-fast/app/v1/inventory/goodsDetail',//详情
    //供应商端
    getProcessList : LOCALURL + '/renren-fast/app/v1/process/list',//获取业务流程表
    getNewsList:LOCALURL + '/renren-fast/app/v1/news/list',//获取查询早报列表
    getHomeBanner:LOCALURL +'/renren-fast/app/v1/homebanner/list',//获取home轮播图列表
    invoiceData:LOCALURL +'/renren-fast/info/invoiceData',//查询供应商入库未到发票数据
}

module.exports = API
