import Vue from 'vue'
import Router from 'vue-router'
import guard from './guard'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home'),
    meta: {
      title: '看板'
    }
  },
  {
    path: '/form',
    name: 'form',
    component: () => import('@/views/form'),
    meta: {
      title: '报表'
    }
  },
  {
    path: '/supplier',
    name: 'supplier',
    component: () => import('@/views/supplier'),
    meta: {
      title: '报表详情'
    }
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my'),
    meta: {
      title: '我的'
    }
  },

  {
    path: '/info',
    name: 'info',
    component: () => import('@/views/info'),
    meta: {
      title: '报表详情'
    }
  },
  {
    path: '/point',
    name: 'point',
    component: () => import('@/views/point'),
    meta: {
      title: '盘点'
    }
  },
 
  {
    path: '/point_style/:index',
    name: 'point_style',
    component: () => import('@/views/point_style'),
    meta: {
      title: '盘点类型'
    }
  },
  {   
    path: '/point_style/details/:id/:barCode',
    name: 'details',
    component: () => import('@/views/details'),
    meta: {
      title: '详情明细'
    }
  },
  {
    path: '/helper',
    name: 'helper',
    component: () => import('@/views/helper'),
    meta: {
      title: '助手页面'
    }
  },
  {
    path: '/point_style/diff/:id',
    name: 'diff',
    component: () => import('@/views/diff'),
    meta: {
      title: '差异查看'
    }
  },

  {
    path: '/login2',
    name: 'login2',
    component: () => import('@/views2/login'),
    meta: {
      title: '博爱智供'
    }
  },
  {
    path: '/form2',
    name: 'form2',
    component: () => import('@/views2/form'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/index2',
    name: 'index2',
    component: () => import('@/views2/index'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/home2',
    name: 'home2',
    component: () => import('@/views2/home'),
    meta: {
      title: '登录'
    }
  },
  
  {
    path: '/my2',
    name: 'my2',
    component: () => import('@/views2/my'),
    meta: {
      title: '我的'
    }
  },
  {
    path: '/text',
    name: 'text',
    component: () => import('@/views2/text'),
    meta: {
      title: '早报列表'
    }
  },
  {
    path: '/business/:id',
    name: 'business',
    component: () => import('@/views2/business'),
  }, 
  {
    path: '/info2',
    name: 'info2',
    component: () => import('@/views2/info'),
    meta: {
      title: '报表详情'
    }
  },
  {
    path: '/tr',
    name: 'tr',
    component: () => import('@/views2/tr'),
    meta: {
      title: '报表详情'
    }
  },
]

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
 
export default guard(new Router({
  mode:'hash',
  routes
}))
